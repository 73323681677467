import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Sync.css";

const SyncStatus = () => {
  const [loadingSync, setLoadingSync] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
    const [syncStatus, setSyncStatus] = useState(null);
  const [counter, setCounter] = useState(0);
  const [error, setError] = useState(null);
  const intervalRef = useRef(null);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Función para iniciar el contador
  const startCounter = () => {
    setCounter(0); // Reinicia el contador
    intervalRef.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);
  };

  // Función para detener el contador
  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const checkSyncStatus = async () => {
    if (loadingSync) return;  // Evitar solicitudes simultáneas

    setLoadingSync(true);  // Inicia carga para sincronización
    setError(null);
    startCounter();
  
    try {
      const response = await axios.get("https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/syncproduct");
      const status = response.data.sync; // Extraer el estado de sincronización
      setSyncStatus(status);
    } catch (error) {
      console.error("Error al consultar el estado de sincronización:", error);
      setError("Error al verificar el estado de sincronización.");
    } finally {
      setLoadingSync(false);  // Detener carga para sincronización
      stopCounter();
    }
  };

  const handleDeleteProducts = async () => {
    const confirmDelete = window.confirm("¿Estás seguro de borrar estos productos?");
    if (!confirmDelete) return; // Si el usuario cancela, no continúa

    setLoadingDelete(true);  // Inicia la carga visual
    setError(null);

    try {
        const response = await axios.post(
            "https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/deleteproduct",
            {}, 
            {
                headers: { "Content-Type": "application/json" },
                responseType: 'json' // Asegura que axios maneje la respuesta como JSON
            }
        );

       // console.log("Respuesta recibida:", response);

        let responseData;

        // Verifica si la respuesta tiene un body anidado
        if (typeof response.data === "string") {
            responseData = JSON.parse(response.data);
        } else {
            responseData = response.data;
        }

        if (typeof responseData.body === "string") {
            responseData = JSON.parse(responseData.body);
        }

        if (response.status === 200 && responseData.message) {
            alert(responseData.message);
        } else {
            alert("Operación completada, pero la respuesta no es la esperada.");
        }
    } catch (error) {
        console.error("Error al eliminar productos:", error);
        setError("Error al intentar eliminar los productos.");
        alert("Error al intentar eliminar los productos.");
    } finally {
        setLoadingDelete(false);
    }
};


  

  useEffect(() => {
    return () => {
      stopCounter();
    };
  }, []);

  return (
    <div className="sync-status">
      <div className="frist-group">
      {error && <div className="error-message">{error}</div>}




      {/* Botón para verificar estado de sincronización */}
      <button
        type="button"
        className="btn-check-status"
        onClick={checkSyncStatus}
        disabled={loadingSync}
      >
        {loadingSync ? `Verificando... ${formatTime(counter)}` : "Verificar estado"}
      </button>

      <div className="boton-delete">
              {/* Botón para borrar productos */}
      <button
        type="button"
        onClick={handleDeleteProducts}
        className="btn-danger"
        disabled={loadingDelete}
        
      >
        Borrar productos
      </button>

      </div>
      </div>
<div>
      {/* Mostrar estado de sincronización */}
      {syncStatus === 1 ? (
        <button type="button" className="btn-sync-success">
          Productos sincronizados
        </button>
      ) : syncStatus === 0 ? (
        <button type="button" className="btn-sync-warning">
          No está sincronizado
        </button>
      ) : null}

</div>



    </div>
  );
};

export default SyncStatus;
